import { useEffect, useState } from 'react';

import type { NextPage } from 'next';
import { useRouter } from 'next/router';

import Layout from '@/components/common/Layout';
import Spinner from '@/components/common/Spinner';
import { ExitValidationEnum } from '@/lib/models/exitValidationEnum';
import { useValidation } from '@/lib/swr/useValidation';

const Home: NextPage = () => {
  const [exitValue, setExitValue] = useState<ExitValidationEnum | undefined>(undefined);
  const { validation, isLoading, isError } = useValidation();
  const router = useRouter();

  useEffect(() => {
    if (exitValue) {
      if (window.exitValidationModule) {
        window.exitValidationModule(exitValue);
      }
      setExitValue(undefined);
    }
  }, [exitValue]);

  const introQuestionsComplete = () => {
    if (validation) {
      return (
        validation.startValidationPurpose &&
        validation.startValidationPurpose.length > 0 &&
        validation.startValidationTransport.length > 0
      );
    } else return false;
  };

  const noTracksValidated = () => {
    if (validation) {
      return validation.tracks.filter((track) => !!track.timeOfValidation).length == 0;
    } else return false;
  };

  const someTracksValidated = () => {
    if (validation) {
      return validation.tracks.filter((track) => !!track.timeOfValidation).length > 0;
    } else return false;
  };

  const allTracksValidated = () => {
    if (validation) {
      return (
        validation.tracks
          .filter((track) => track.typeId != 1)
          .filter((track) => track.typeId != 99)
          .filter((track) => !track.timeOfValidation).length == 0
      );
    } else return false;
  };

  const validationSubmitted = () => {
    if (validation) {
      return validation.validationCompleted;
    } else return false;
  };

  const exitQuestionsCompleted = () => {
    if (validation) {
      return validation.f90002 != -1 && validation.f90003 != -1 && validation.f90004 != -1;
    }
  };

  if (isLoading || isError) return <Spinner />;

  if (validation?.onlyAbroad) {
    router.push('/user-abroad');
  } else if (validation?.tracks.length == 0) {
    router.push('/no-tracks');
  } else if (!introQuestionsComplete()) {
    router.push('/intro-questions');
  } else if (noTracksValidated()) {
    router.push('/dashboard');
  } else if (someTracksValidated() && !allTracksValidated()) {
    router.push('/track-wizard');
  } else if (!validationSubmitted()) {
    router.push('/dashboard-validated');
  } else if (validationSubmitted() && !exitQuestionsCompleted()) {
    router.push('/exit-questions');
  } else if (exitQuestionsCompleted()) {
    router.push('/completed');
  }

  return (
    <Layout>
      <Spinner />
    </Layout>
  );
};

export default Home;
